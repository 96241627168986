.jrSkeleton{
  width: 100%;
  height: 10em;
  display: flex;
  background-color: white;
  border-bottom: 2px solid #dddddd;
}

.jrSkeleton>div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width:100%;
}

.jrSkeleton .imgSkeleton{
  font-size: 1vw;
  background-color: #dddddd;
  width: 7em;
  max-width: 8rem;
  height: 7em;
  max-height: 70%;
}

.jrSkeleton .pSkeleton{
  width: 80%;
  height: 1em;
  background-color: #dddddd;
  border-radius: 20px;
}