.employee{
  /* margin: 0.7rem 0 0.7rem 0; */
  padding: 0.5rem 0 0.5rem 0;
  /* border: 1px solid #dddddd; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  display: flex;
  align-items: center;
}

.gray {
  background-color: #e7e7e7;
}

.employee:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.editEmployee {
  background-color: #01522f;
  color: #ffffff;
  border-radius: 5px;
  border: 0;
  height: 1.3rem;
  width: 1.3rem;
}
.deactivateEmployee {
  background-color: #01522f;
  color: #ffffff;
  border-radius: 5px;
  border: 0;
  height: 1.3rem;
  width: 1.3rem;
}

.editEmployee:hover {
  cursor: pointer;
  background: #018049;
}
.deactivateEmployee:hover {
  cursor: pointer;
  background: #018049;
}

.employeeActions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  margin-right: 0.5rem;
}

.employee>img{
  width: 4em;
  height: 4em;
  border-radius: 100%;
  background-color: #dddddd;
}

.name{
  width:100%;
}
.name>p{
  padding: 0;
  margin: 0;
}
.name>p:first-child{
  color: #01522f;
}
.name>p:last-child{
  color:#aaaaaa;
}

.buttons{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 5%;
  min-width: 4em;
}
.buttons>button{
  height:1.5em;
  width:1.5em;
  border-radius: 100%;
  border: none;
  background-color: white;
  cursor: pointer;
}
.buttons>button:hover{
  background-color: #dddddd;
}

.expanded th{
  background-color: #eeeeee;
}

.expanded{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
}
.iconMargin{
  margin-left: 25px;
}
