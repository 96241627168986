.styledButton {
  text-shadow: 1px 1px 3px #666666;
  /* font-family: Arial; */
  color: #ffffff;
  background: #1890ff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: none;
  width: fit-content;
  transition: 0.15s ease-in;
  margin-bottom: 1rem;
}


.styledButton:hover {
  cursor: pointer;
  background: #096dd9;
  text-decoration: none;
}

@media only screen and (max-width:550px) {
  .styledButton {
    width: fit-content
  }
}

.addButton {
  opacity: 1;
  display: inline-block;
  width: 100%;
  height: auto;
  transition: 0.2s ease-in;
  backface-visibility: hidden;
}

.addButtonContainer {
  width: 3%;
  border: none;
  background: none;
}

.addButtonContainer:hover .addButton {
  opacity: 0.5;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}

.styledSelect{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, white, white);
  background-position:
    calc(100% - 10px) calc(1em + 2px),
    calc(100% - 5px) calc(1em + 2px),
    calc(100% - 23px) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  background-color: #1890ff;
  color: #ffffff;
  padding: 0.5em 0.2em;
  border: none;
}

.styledSelectActive{
  background-image:
    linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, white, white);
  background-position:
  calc(100% - 5px) 1em,
    calc(100% - 10px) 1em,
    calc(100% - 23px) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  outline: 0;
}

