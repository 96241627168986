* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.contentBox {
  display: flex;
  /* margin-top: 1vh; */
  height: 77vh;
  /* height: 100%; */
  width: 100%;
  /* height: 100%; */
  /* margin-bottom: -25px; */
  /* padding:0; */
}

/*firefox scrollbar*/
.contentBox * {
  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}
/*chrome scrollbar*/
.contentBox *::-webkit-scrollbar {
  width: 5;
}
.contentBox *::-webkit-scrollbar-track {
  background: transparent;
}
.contentBox *::-webkit-scrollbar-thumb {
  background: grey;
}
.contentBox *::-webkit-scrollbar-thumb:hover {
  background: #555555;
}
