.serviceAddresses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.addServiceAddressButton {
  opacity: 1;
  width: 100%;
  height: auto;
  transition: 0.2s ease-in;
  backface-visibility: hidden;
  border: none;
  background: none;
}

.addServiceAddressButtonContainer:hover .addServiceAddressButton {
  opacity: 0.7;
  cursor: pointer;
}

.addServiceAddressButtonContainer {
  width: 2.5%;
  border: none;
  background: none;
  margin-right: 0.7rem;
}

@media only screen and (max-width: 1500px) {
  .addServiceAddressButtonContainer {
    width: 3%;
  }
}
.searchBar {
  margin-right: 0.7rem;
}

.shadowBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 50vw;
  min-width: 25em;
  max-height: calc(85vh - 10.5em);
  box-shadow: 0 10px 15px 5px rgba(0, 0, 0, 0.1);
}

/* .tableContainer {
  width: 100%;
  align-self: center;
} */
