.topBar {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 96% !important;
  /* height: min-content; */
  height: 1rem;
  background-color: "#fff";
}

#filter {
  width: 3rem !important;
  background-color: aqua;
}

#filter option {
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

/* .topBar> *{
  margin: 0.125em 0.5em;
} */

.dropdownContainer {
  order: 3;
  position: relative;
  display: inline-block;
  height: 2.6em;
  width: fit-content;
  z-index: 2;
}
.dropdown {
  padding-right: 40px;
  width: fit-content;
}
.dropdownMenu {
  display: block;
  position: absolute;
  height: min-content;
  background-color: white;
  min-width: 20em;
  box-shadow: 0px 8px 14px 1px rgba(0, 0, 0, 0.3);
  padding: 12px 16px;
  z-index: 3;
}
.dropdownMenu label {
  display: flex;
  padding: 0 0.5em;
  line-height: 1em;
  width: 100%;
  height: 3em;
  margin-bottom: 5px;
  align-items: center;
  border: 1px solid #dddddd;
  background-color: white;
  color: black;
  border-radius: 2em;
  user-select: none;
}
.dropdownMenu label:hover {
  /* border: 1px solid white; */
  background-color: #dddddd;
}
.dropdownMenu label > img {
  height: 85%;
  border-radius: 100%;
}
.dropdownCkBox {
  visibility: hidden;
  position: absolute;
}
.dropdownCkBox:checked + label {
  border: 2px inset #dddddd;
  background-color: #01552f;
  color: white;
}
.dropdownCkBox:checked + label:hover {
  background-color: #096dd9;
}

.dropdownMenu .brDiv {
  margin: 1em 0;
  height: 2px;
  width: 100%;
  background-color: #dddddd;
}

.dateSlider {
  margin: 0 0.6em;
  order: 1;
}

.toMap {
  order: 2;
}

.routingBtn {
  order: 3;
}

.styledSelect {
  /* padding-right: 1rem; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, white, white);
  background-position: calc(100% - 10px) calc(1em + 2px),
    calc(100% - 5px) calc(1em + 2px), calc(100% - 23px) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  background-color: #01552f;
  color: #ffffff;
  padding: 0.5em 2em 0.5em 0.5em;
  border: none;
}

.styledSelectActive {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, white, white);
  background-position: calc(100% - 5px) 1em, calc(100% - 10px) 1em,
    calc(100% - 23px) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  outline: 0;
}

.tabElement {
  font-size: 14px !important;
}

.icon {
  font-size: 18px !important;
}

@media screen and (max-width: 623px) {
  .tabElement {
    font-size: 10px !important;
  }

  .icon {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 745px) {
  .dateSlider {
    order: 3;
  }
}
@media screen and (max-width: 518px) {
  .filterSelect {
    order: 3;
  }
}
@media screen and (max-width: 430px) {
  .topBar {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 347px) {
  .marketToggle {
    order: 3;
  }
}
@media screen and (max-width: 281px) {
  .topBar {
    height: 1rem;
  }
}
