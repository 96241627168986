.commmunicationLayout {
    height: calc(100% - 114px);
    background-color: #ffffff;
  }
  .communicationDesktop {
    margin: 30px;
  }
  .NoSelectedDiv {
    display: flex;
    height: 100%;
  }
  .noSelectedImg {
    position: relative;
    top: 45%;
    transform: translateY(-50%);
  }
  .commmunicationLayout .ant-layout-content{
    margin:0!important;
    padding:0!important;
  
  }
  .commmunicationLayout .ant-layout-sider,
  .commmunicationLayout .ant-layout-header,
  .commmunicationLayout .ant-layout-content,
  .commmunicationLayout .ant-layout-footer {
    background-color: rgb(255, 255, 255);
  }
  .Newrequest-btn{
    display:block;
    
    margin-left: 30%;
    justify-content:flex-end ;
  } 
  .maintrequest-btn{
    
    display:block;
    margin:auto;
  }
  .servrequest-btn{
    
    display:block;
    margin:auto;
  }
  
  .linkrequest-btn {
    
    display: block;
    margin: auto;
  }

  /* #communicationContent {
    overflow-y: hidden;
  } */
  
  #listingHeader {
    border-bottom: thin solid #ffffff;
    flex: 8% 1;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
  }
  .listContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .listScrollContainer {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .threadList {
    flex: 92% 1;
  }
  #commBody {
    height: 100%;
  }
  #commSiderLeft {
    border-right: thin solid #e3e3e3;
  }
  #commSiderRight {
    border-left: thin solid #e3e3e3;
  }
  
  #commBodyHeader {
    height: 52px;
    border-bottom: thin solid #e3e3e3;
  }
  #commBodyMain {
    border-bottom: thin solid #e3e3e3;
    flex: 1;
  }
  #commBodyFooter {
    background-color: #e3e3e3;
  }
  
  .backbtnComms {
    flex: 10%;
  }
  .peopleHeader {
    flex: 70%;
  }
  .detailsHeader {
    flex: 20%;
    color: #4d7cfe;
  }
  .headerInfo {
    height: 52px;
    line-height: 52px;
  }
  .activeThread,.activeThread:hover{
    background-color: #e6f7ff;
  }
  .threadListing:hover .threadName{
    color:#0042f7
  }
  
  @media screen and (max-width: 600px) {
    .noScroll {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }