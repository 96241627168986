.styleButton {
  text-shadow: 1px 1px 3px #666666;
  color: #ffffff;
  background: #1890ff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: none;
  min-width: 9rem;
  transition: 0.15s ease-in;
}

.styleButton:hover {
  cursor: pointer;
  background: #096dd9;
  text-decoration: none;
}