.jobDisplay{
  width: 50vw;
  display: flex;
  flex-direction: column;
  border-right: 2px #dddddd solid;
  height:100%;
  background-color: #FAFAFA;
  /* padding: 0.25rem;
  overflow-x: hidden; */
}
.emptyDisplay{
  font-size: 14px;
  display: flex;
 margin-left: 5px;
  
  height:100%;
  background-color: #FAFAFA;
  margin-top: 25px;
  /* padding: 0.25rem;
  overflow-x: hidden; */
}

.jobDisplay *{
  outline: none;
}

.toggleContainer{
  margin: 0 1em;
}

.toggle{
  border: #1890ff 2px solid;
  padding: calc(0.5rem - 2px) calc(1rem - 2px);
  text-shadow: 1px 1px 3px #666666;
  font-family: Arial, sans-serif;
  color: white;
  background-color: #1890ff;
  text-decoration: none;
  transition: 0.15s ease-in;
}
.toggle:hover{
  border: #096dd9 2px solid;
  background-color: #096dd9;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.pressedToggle{
  text-shadow: 1px 1px 3px rgba(102, 102, 102, 0.5);
  font-family: Arial, sans-serif;
  color: #1890ff;
  background: white;
  text-decoration: none;
  padding: calc(0.5rem - 2px) calc(1rem - 2px);
  border: 2px solid white;
  border-top: 2px solid #dddddd;
  transition: 0.15s ease-in;
}

.pressedToggle:hover {
  cursor: pointer;
  background: #dfdfdf;
  border-color: #dfdfdf;
  text-decoration: none;
}

.smallButton{
  border: #1890ff 2px solid;
  padding: 0;
  text-shadow: 1px 1px 3px #666666;
  color: white;
  background-color: #1890ff;
  text-decoration: none;
  transition: 0.15s ease-in;
}
.smallButton:hover{
  background-color: #096dd9;
  color: #096dd9;
  cursor: pointer;
}

.menuContainer{
  position: absolute;
  z-index: 3;
  width:87vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.001);
  transform: translate(-3em, -8em);
}

.dropdownMenu{
  display: flex;
  position: absolute;
  align-items: center;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 14px 1px rgba(0,0,0,0.3);
  padding: 12px 16px;
  z-index: 4;
  transform: translate(3em, 8em);
}

.overdueContainer{
  margin-left:1em;
}
.overdueContainer>input{
  visibility: hidden;
  z-index: -1;
  position: absolute;
}
.overdueContainer>div{
  width: fit-content;
  height: min-content;
  padding: 0.5em 1em;
  border: 2px solid #1890ff;
  background-color: #1890ff;
  color: white;
}
.overdueContainer>div:hover{
  border: 2px solid #096dd9;
  background-color: #096dd9;
}
.overdueContainer>input:checked + div{
  color:black;
  background-color: white;
  border: inset 2px #dddddd;
}
.overdueContainer>input:checked + div:hover{
  background-color: #dddddd;
}

.jobDisplayList{
  display:flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.btnContainer{
  width:100%;
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 850px) {
  .jrSkeleton .imgSkeleton{
    font-size: 3vw;
  }
  .jobDisplay{
    width: 100vw;
  }
}
/* @media screen and (max-width: 600px) {
  .jobDisplay{
    width: 70%;
  }
} */