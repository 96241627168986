.multi-range-slider {
	padding: 1em 0;
  width: 15em;
  border: none;
	box-shadow: none;
  z-index: 4;
}
.multi-range-slider .bar-inner {
	background-color: #1890ff;
  border: none;
	box-shadow: inset 0px 0px 5px rgba(0,0,0,0.5);
}
.multi-range-slider .thumb {
  top: 2px;
}
.multi-range-slider .thumb::before {
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5), inset 0px 0px 5px gray;
}
.multi-range-slider .caption {
	bottom: -25px;
	left: 0px;
}
.multi-range-slider .thumb .caption * {
	display: none;
}
.multi-range-slider .ruler {
	display: none;
}
.multi-range-slider .ruler .ruler-rule {
	display: none;
}
.multi-range-slider .labels {
	display: none;
}