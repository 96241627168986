:root {
  --contrastText: #fff;
  --fixDenColor: #01522f;
  --hoverColor: #017a46;
  --outlineShadowColor: rgba(1, 122, 70, 0.2);
  --clickColor: #77c7a2;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Brockmann", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gm-style-iw-d {
  scrollbar-width: none !important;
}

/* .css-rj7o46 {
  background-color: #77c7a2;
} */

#main_card {
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  width: 100%;
}

/*firefox scrollbar*/
* {
  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}
/*chrome scrollbar*/
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: grey;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555555;
}
