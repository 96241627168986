.submitBidForm{
  position: absolute;
  z-index: 10000;
  top: 0;
  left:0;
  width: 100vw;
  height:100vh;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.totalRow{
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  gap: 2rem;
  align-items: center;
  justify-content: flex-end;
}

.onHover:hover {
  background-color: #dddddd;
}

.addBtn{
  width: 1em;
  height: 1em;
  line-height: 0.8em;
  padding: 0;
  border-radius: 100%;
  border:none;
  color: white;
  background-color: #01522F;
}

.addBtn:hover{
  opacity: 0.7rem;  
  cursor: pointer;
}

.submitBidForm>form>*{
  margin: 0 0.5em 1em;
}

.submitBidForm>form *{
  outline: none;
}

.submitBidForm>form{
  background-color: white;
  padding: 0 1em 1em 0;
  border: 3px #dddddd solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 24em;
}

.closeBtnContainer{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 0;
  margin: 0 !important;
}

.closeBtn{
  transform: rotate(45deg);
}

.dateTimeLabel{
  display: flex;
  width:100%;
}
.dateTimeLabel>p{
  width:max-content;
  min-width: 5em;
}
.dateTimeLabel>input{
  margin-right: 3px;
}

.bidFormLabel{
  display: flex;
  width:100%
}

.bidFormLabel>input{
  width: 100%;
  padding-left: 0.25em;
}

.bidFormLabel>p{
  width: max-content;
  min-width: 5em;
  display: inline-block;
}

.submitBidForm input[type="text"]{
  padding-left: 0.15em;
}

.submitBidForm .textareaLabel{
  width:100%;
}

.submitBidForm .textareaLabel>textarea{
  min-width: none;
  width:100%;
  padding-left: 0.25em;
}


.submitBidForm>form>div{
  width:100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 386px) {

  .submitBidForm{
    width: 100%;
    min-width: none;
  }
  .submitBidForm *{
    min-width: none;
  }
  
}