@import "~antd/dist/antd.min.css";
/* HTML Elements */
html,
body,
.App,
#root,
.h100 {
  height: 100%;
}

.ant-modal-body .invoiceDetails {
  /* margin: 0 !important; */
}

.fixed-width {
  min-width: 10rem !important;
  max-width: 10rem !important;
}

.MuiTableCell-sizeMedium {
  font-size: 0.75rem !important;
  min-width: 3.25rem;
  max-width: 3.25rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@font-face {
  font-family: "Brockmann";
  src: url("./Fonts/Brockmann/Brockmann-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brockmann";
  src: url("./Fonts/Brockmann/Brockmann-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* body {
  font-family: 'Brockmann', sans-serif;
} */

body {
  /* font-family: "Rubik", sans-serif !important; */
  font-family: "Brockmann", sans-serif !important;
  background: #fafafa;
  color: rgb(0, 0, 0);
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0em;
}

a:hover,
a:focus,
a:hover .titleHover {
  transition: all 0.3s;
  color: #017a46;
}

.link {
  color: #017a46 !important;
}
.link-sm {
  color: #017a46 !important;
  font-size: 12px;
}
h1 {
  font-size: 26px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
.caption,
label.form-label {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.JRSubtitle {
  font-size: 16px;
  color: #778ca2;
  display: inline;
  margin-right: 0.5rem;
}
.centerDiv {
  margin: auto;
  width: 60%;
  padding: 10px;
}
.WorkOrderReport {
  font-size: 16px;
  color: #778ca2;
  display: inline;
  margin-right: 0.5rem;
}
.caption-g {
  font-weight: 500;
  font-size: 14px;
  color: #778ca2;
}
.text-right {
  text-align: right;
}
.g-text {
  color: #778ca2;
}
.g-text-lg {
  font-size: 14px;
  color: #778ca2;
}
.g-text-sm {
  color: #778ca2;
  font-size: 12px;
}
.blue-text-lg {
  color: #017a46;
  font-size: 14px;
}
.blue-text-sm {
  color: #017a46;
  font-size: 12px;
}
.blue-text {
  color: #017a46;
}
.b-text-lg {
  color: black;
  font-size: 14px;
}
.b-text-sm {
  color: black;
  font-size: 12px;
}
.b-text {
  color: black;
}
.w-text-lg {
  color: white;
  font-size: 14px;
}
.w-text-sm {
  color: white;
  font-size: 12px;
}
.w-text {
  color: white;
}
.w-text-imp {
  color: white !important;
}
.errorMessage {
  color: red;
}
/* antd Button */
.ant-btn {
  font-size: 12px;
}
.ant-btn-primary:hover,
.ant-btn-primary:active {
  background-color: #017a46;
}

.btnOutline {
  border: thin solid #017a46;
  color: #017a46;
}
.btnOutline:hover,
.btnOutline:active {
  background-color: #017a46;
  color: white;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.deleteBtn {
  cursor: pointer;
}
.pl-0 {
  padding-left: 10px !important;
}
/* Custom Classes */
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-list {
  font-size: 14px;
  color: #98a9bc;
}
.fs-list-sm {
  font-size: 12px;
  color: #98a9bc;
}

.pl-15pc {
  padding-left: 15% !important;
}
.px-15pc {
  padding-left: 15%;
  padding-right: 15%;
}

.circleImage {
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
}
.image40 {
  width: 48px;
  height: 48px;
}
.image32 {
  height: 32px;
  width: 32px;
}
.width10 {
  width: 10% !important;
}
.width20 {
  width: 20% !important;
}
.width30 {
  width: 30%;
}
.width50 {
  width: 50% !important;
}
.width50fixed {
  width: 50px !important;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.width45 {
  width: 45%;
}
.width65 {
  width: 65%;
}
.width80 {
  width: 80%;
}
.width90 {
  width: 90%;
}
.width100 {
  width: 100% !important;
}
span a:focus {
  color: #1890ff;
}
#main-body {
  background-color: #f8fafb;
  height: calc(100% - 64px) !important  ;
}
main {
  margin-left: auto;
  width: 100%;
}
.selectedBlue {
  border: thin solid #017a46 !important;
}
.selectedGreen {
  border: thin solid #65d600 !important;
}
.selectedOrange {
  border: thin solid #ffac05 !important;
}

.selectedBlueBk {
  border: thin solid #017a46 !important;
  background-color: #e6f7ff;
}
/* Media displays */
@media screen and (max-width: 600px) {
  * {
    font-size: 12px !important;
  }
  .description {
    font-size: 32px !important;
  }
  .work {
    font-size: 18px !important;
  }
  .tradesperson {
    font-size: 24px !important;
  }
  .features {
    font-size: 8px !important;
  }
  .fixden-work-tradespeople {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 768px) {
  .hamburger-icon {
    display: block;
  }
  /* antd RangePicker - used to fit on mobile screens better */

  .ant-picker-time-panel-column {
    width: 46px;
  }
  .ant-picker-date-panel .ant-picker-body {
    padding: 0;
  }
  .ant-picker-panels {
    font-size: 12px;
  }
  .ant-picker-date-panel {
    width: 260px;
  }
}
@media screen and (max-width: 1130px) {
}
.height500Fixed {
  height: 500px;
}
.height250Fixed {
  height: 250px;
}
.height300Fixed {
  height: 300px;
}
.height310Fixed {
  height: 310px;
}

.height30Fixed {
  height: 30px;
}
.height50 {
  height: 50%;
}
.height80 {
  height: 80%;
}
.height98 {
  height: 98%;
}
.nav-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.w50 {
  width: 50%;
  margin-left: 0;
  margin-top: 0;
  border-left: none;
}

#maintenanceFrame {
  width: 100%;
  height: 100%;
}
#MainLayout {
  height: 100%;
}

option:focus {
  background-color: #fff;
  outline: none;
  border: none;
  box-shadow: none;
}

.highlight {
  background-color: yellow;
}
.highlightFocus {
  background-color: #65d600;
}

.mbody {
  overflow-y: auto;
}
.of-hidden {
  overflow-y: hidden;
  max-height: 100%;
}
.modal-body {
  overflow-y: auto;
}
.flex-5 {
  flex: 5%;
}
.flex-7 {
  flex: 7%;
}
.flex-10 {
  flex: 10%;
}
.flex-12 {
  flex: 12%;
}
.flex-15 {
  flex: 15%;
}
.flex-18 {
  flex: 18%;
}
.flex-20 {
  flex: 20%;
}
.flex-25 {
  flex: 25%;
}
.flex-30 {
  flex: 30%;
}
.flex-35 {
  flex: 35%;
}
.flex-40 {
  flex: 40%;
}
.flex-45 {
  flex: 45%;
}
.flex-50 {
  flex: 50%;
}
.flex-60 {
  flex: 60%;
}
.flex-65 {
  flex: 65%;
}
.flex-70 {
  flex: 70%;
}
.flex-75 {
  flex: 75%;
}
.flex-80 {
  flex: 80%;
}

.buttonOutline1 {
  background-color: white !important;
  border: thin solid #017a46;
  color: black !important;
}
.selected {
  background-color: #017a46;
  color: white;
}
.selected:hover,
.selected:active,
.selected:focus {
  background-color: #017a46;
  color: white;
}
.boxShadowNone {
  box-shadow: none !important;
}
.height95 {
  height: 95%;
}

.padding0 {
  padding: 0;
}
.btn-primary {
  background-color: #017a46 !important;
  border-color: #017a46 !important;
  color: white !important;
}
.btn-secondary {
  background-color: #f2f4f6 !important;
  border-color: #f2f4f6 !important;
  color: #778ca2 !important;
}
.btn-secondary:hover {
  border-color: #017a46 !important;
  color: #017a46 !important;
}
.btn-primary:hover {
  background-color: #017a46 !important;
  border-color: #017a46 !important;
}
.btn-outline-primary {
  background-color: white !important;
  border-color: #017a46 !important;
  color: #017a46 !important;
}
.btn-outline-primary:hover {
  background-color: #017a46 !important;
  border-color: #017a46 !important;
  color: white !important;
}
.btn {
  box-shadow: none !important;
}

.borderLine {
  width: 100%;
  height: 0.5px;
  margin-top: -1px;
  border-width: 0.5px;
  border: thin solid #e3e3e3;
  display: block;
}
.notVisable {
  visibility: hidden;
}
.pointer {
  cursor: pointer;
}

svg {
  vertical-align: unset;
}
.ant-card {
  color: inherit;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #017a46 !important;
  border-color: #017a46 !important;
}

.blueLink {
  color: #017a46 !important;
  font-weight: 600;
  cursor: pointer;
}

.header {
  background-color: white;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #017a46;
}

.status-green {
  color: #65d600 !important;
}
.status-orange {
  color: #ffac05 !important;
}
.status-yellow {
  color: #e7c70d;
}
.status-red {
  color: #ff4496 !important;
}
.status-blue {
  color: #4d7cfe !important;
}
.status-lightGreen {
  color: rgb(36, 199, 36);
}
.status-darkGreen {
  color: rgb(18, 136, 18);
}
.status-lightBlue {
  color: rgb(93, 140, 241) !important;
}
.status-darkBlue {
  color: rgb(25, 59, 151) !important;
}
.status-lightGrey {
  color: rgb(175, 175, 175);
}
.status-grey {
  color: rgb(116, 114, 114);
}
.status-darkRed {
  color: rgb(138, 23, 23);
}
.status-darkGrey {
  color: rgb(66, 38, 38) !important;
}
.loadIcon {
  position: absolute;
  left: 50%;
  margin-left: -32px;
  top: 50%;
  margin-top: -32px;
  width: 64px;
  height: 64px;
}
.image {
  width: auto;
  height: auto;
}
.ant-avatar > img {
  object-fit: cover;
}
.verticalMenuIcon {
  width: auto;
  height: auto;
  max-width: 16px;
  max-height: 16px;
  cursor: pointer;
}
.steadyIcon {
  max-width: 199px;
  max-height: 64px;
  width: auto;
  height: auto;
  cursor: pointer;
}
#workLogContainer {
  width: 90%;
  margin: auto;
}
.displayFont > * {
  margin-top: 5px;
  margin-right: 5px;
}
.fc-header {
  position: fixed;
}

.sbh32 {
  height: 32px !important;
}

.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.hideScrollBar:hover {
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: unset;
}
.hideScrollBar::-webkit-scrollbar {
  display: unset;
}
.noMarginTop {
  margin-top: 0px !important;
}

.modal-title {
  font-size: 18px !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.hover-row:hover {
  background-color: #dddddd; /* Change the background color as desired */
  cursor: pointer;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
