#createRequestForm {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 1rem;
  border: 3px solid #dddddd;
  border-radius: 30px;
  background-color: white;
  width: fit-content;
  height: fit-content;
}

.attachment {
  width: min-content;
  height: 3em;
  margin-right: 7px;
}

.attachment > img {
  height: 100%;
}

.attachments {
  /* position: relative; */
  align-self: center;
  justify-self: center;
  z-index: 3;
  top: 0.5;
  display: flex;
  height: 4em;
  width: 100%;
  max-width: 30vw;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}

/*chrome scrollbar*/
.attachments::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}
.attachments::-webkit-scrollbar-track {
  background: transparent;
}
.attachments::-webkit-scrollbar-thumb {
  background: grey;
}
.attachments::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

.gap {
  display: flex;
  gap: 1rem;
  align-self: center;
  justify-self: center;
}

#closeButton {
  align-self: center;
}

#form {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3rem 0;
}

#formSubmit {
  align-self: center;
}

#document {
  align-self: center;
  /* padding-left: 3rem; */
}

.reqBox1 {
  display: flex;
  gap: 1.5rem;
  flex-direction: row;
  align-items: center;
}

.requestInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25.5rem;
}

.reqBox2 {
  flex-direction: column;
  align-items: center;
}

.inputReq {
  width: 12rem;
  height: 1.5rem;
}

.selectRequest {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.textInput {
  width: 100%;
}

textarea.textInput {
  resize: none;
}

#radioInput {
  width: 100%;
}

@media only screen and (max-width: 560px) {
  #form {
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.7rem;
    padding: 0;
  }
  #category {
    font-size: 0.7rem;
  }

  #unit {
    font-size: 0.7rem;
  }

  .previousVendors {
    font-size: 0.7rem;
  }

  #formSubmit {
    font-size: 0.7rem;
  }

  #document {
    font-size: 0.7rem;
  }

  .inputReq {
    width: 10rem;
  }

  .requestInput {
    width: 21.5rem;
  }
}

@media only screen and (max-width: 450px) {
  .createRequestHeading {
    font-size: 1.15rem;
  }

  .inputReq {
    width: 8rem;
  }

  .requestInput {
    width: 17.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .createRequestHeading {
    font-size: 1rem;
  }

  .radioSelect {
    width: 8.8rem;
  }

  .inputReq {
    width: 7rem;
    height: 1rem;
  }

  .requestInput {
    width: 15.5rem;
  }
}

@media only screen and (max-width: 350px) {
  #createRequestForm {
    width: 100%;
    height: 100vh;
    border: 0;
    border-radius: 0;
    box-shadow: 0;
    padding: 3rem 0 0 0;
    align-items: center;
  }

  .createRequestHeading {
    font-size: 0.8rem;
    text-align: center;
  }

  .inputReq {
    width: 5.5rem;
  }

  .radioSelect {
    width: 5.85rem;
  }

  .requestInput {
    width: 12.5rem;
  }
}

@media only screen and (max-width: 310px) {
  .createRequestHeading {
    font-size: 0.8rem;
  }

  .reqBox1 {
    gap: 0.2rem;
  }

  .inputReq {
    width: 4rem;
  }

  .radioSelect {
    width: 3.85rem;
  }

  .requestInput {
    width: 10.5rem;
  }

  .selectRequest {
    align-items: center;
  }
}
