.myMessage {
    background-color: #01522f;
    color: white;
    margin-right: 5px;
    float: right;
    width: fit-content;
  }
  .myMessageTime{
    font-size: 10px; 
    margin-top: 10px;
    margin-right: 5px;
    float: right;
    width: fit-content;
  }
  .container {
    display: flex;
    justify-content: center;
  }
  .center {
    width: 400px; 
    padding: 10px;
    background: #FFFFFF;
    color: #fff;
    font-weight: bold;
    font-family: Tahoma;
  }
  .message__status {
    position: fixed;
    bottom: 100px;
    font-size: 13px;
    font-style: italic;
  }
  
  .otherMessage {
    background-color: #e3e3e3;
    color: black;
    margin-left: 5px;
    float: left;
    width: fit-content;
  }
  
  .otherMessageTime{  
    font-size: 10px; 
    margin-top: 10px;
    margin-left: 5px;
    float: left;
    width: fit-content;
  }
  
  .dayDivider{
    left: 0;
    right: 0;
    border-top: 1px solid rgba(var(--sk_foreground_low_solid,221,221,221),1);
    border-bottom: 0;
    margin: 0 0 -1px 0;
    top: -1px;
    position: relative;
  }
  
  .messageDate{
    margin: auto;
  }
  
  .txtContainerSelf {
    float: right;
    margin: auto;
  }
  .txtContainerOTher {
    float: left;
    text-align: left;
    margin: auto;
  }
  .msgContainer {
    display: flex;
    margin-bottom: 5px;
    width: 90%;
    min-height: 20px;
    word-break: break-word;
  }
  .msgTextContainer {
    flex: 90%;
  }
  .msgText {
    white-space: pre-line;
    border-radius: 1.3em;
    padding: 5px 15px 5px 15px;
  }
  
  .messengerHeader {
    /* flex: 8%; */
    min-height: 60px;
    justify-content: unset;
  }
  .messengerFooter {
    border-top: unset;
    /* flex: 8%;
    min-height: 5px; */
  }
  .messengerBody {
    flex: 84%;
  }
  .messengerContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    
  }
  .backbtnComms {
    max-width: 28px;
    max-height: 28px;
    width: auto;
    height: auto;
  }
  .lh28 {
    line-height: 28px;
  }
  .unreadTxt {
    font-weight: 600!important;
    font-size: 12px;
    color:rgb(0,0,0,0.85)
  }
  .commHeight400 {
    height: 400px !important;
  }
  .commModalFixedHeight {
    height: 464px !important;
  }
  .commModalFixedHeightMessenger {
    height: 528px !important;
  }
  .modalTitleComms {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bigBtn {
    width: 100px;
    height: 100px;
  }
  .threadListing {
    display: flex;
    background-color: white;
    width: 100%;
    border-bottom: thin solid #e3e3e3;
    height: 80px;
    padding: 8px;
  }
  .listContainer {
    display: flex;
    flex-direction: column;
  }
 
  .threadLeft {
    width: 45px;
    margin-right: 0.5rem;
    margin-top: 1rem;
  }
  .threadMain {
    width: 78%;
  }
  .threadRight {
    width: 22%;
    text-align: right;
  }
  .threadName {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
  
  .txtMsg {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a.threadListing{
    color:rgb(0,0,0,0.65);
  }
  a.threadListing:hover{
    color:rgb(0,0,0,0.85);
  }
  .user-online {
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: rgb(0, 219, 0);
    position: absolute;
    top: -3px;
    right: -3px;
    z-index: 2;
  }
  #messageTextArea{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  