.agreement{
  padding: 0 0.5em;
}

.center{
  text-align: center;
}
.center > *{
  padding: 0;
  margin: 0;
}

.agreement th{
  vertical-align: top;
}
.agreement td{
  vertical-align: top;
}
.agreement tr>*:first-child{
  padding-right: 1em;
}

.listItem{
  display:flex;
  align-items: flex-start;
}
.listItem>p{
  min-width: 2.8em;
}

.execution{
  width: 100%;
}
.execution tbody{
  width: 100%;
}

.link{
	align-items: normal;
  background-color: rgba(0,0,0,0);
  border: none;
  border-style: none;
  box-sizing: content-box;
  color: #096dd9; 
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  width: auto;
}
.link:hover{
  text-decoration: underline;
}