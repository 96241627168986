.fullTransaction{
  width: 100%;
  /* overflow-y: scroll; */
}
/* .fullTransaction::-webkit-scrollbar:horizontal{
  height:6px;
} */

.backBtn{
  cursor: pointer;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.expandedContainer{
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  width: 100%;
  align-items: center;
}

.expandedBox{
  border: 2px solid #dddddd;
  background-color: white;
  width:100%;
  max-width: 45em;
}

.dateBox{
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 2px solid #dddddd;
  border-width: 0 0 2px 0;
  border-collapse: collapse;
}
.dateBox>.date:first-child{
  border: 2px solid #dddddd;
  border-width: 0 2px 0 0;
}

.date{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 0 0.5em;
}
.date>h2{
  text-align: center;
  width: 100%;
}

.textBox{
  padding: 0 0.5em;
}
.textBox>p{
  margin-left: 1.5em;
}

.bottomBorder{
  border: 2px solid #dddddd;
  border-width: 0 0 2px;
}

.costLabel{
  text-align: center;
  padding: 0.5em;
}
.costList{
  overflow: hidden;
  margin: 0 1rem;
  /* padding-top: -5px; */
}
.costList>table{
  width: 100%;
  table-layout: fixed;
  background: white;
  border: none;
  background: linear-gradient(90deg, white 0%, #DDDDDD 25%, #DDDDDD 75%, white 100%);
  border-collapse: separate;
  border-spacing:0 2px;
  margin: -2px 0;
}

.costList tr{
  margin: 0;
  background-color: white;
  padding: 1.5em 3em;
}

.costList td{
  padding: 1em;
}

.subtotal>td:last-child{
  border-top: 1px black solid;
}

.costLine:last-child{
  border-bottom: black 1px solid;
}
