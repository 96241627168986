.mainPage{
  background-color: white;
  display:flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* justify-content: space-around; */
}
.split{
  display: flex;
  width: 100%;
  height: 100%;
}
/* @media screen and (max-width: 871px) {
  .split{
    height: calc(100% - 5.4em);
  }
}
@media screen and (max-width: 849px) {
  .split{
    height: calc(100% - 5.7em);
  }
}
@media screen and (max-width: 397px) {
  .split{
    height: calc(100% - 8em);
  }
}
@media screen and (max-width: 352px) {
  .split{
    height: 100%;
  }
} */
