.properties {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
  gap: 0.5rem;
  align-self: center;
  /* background-color: aquamarine; */
}

.propertyTable {
  width: 100%;
}

.propertyTable th {
  border: 1px solid #bdbdbd;
  border-collapse: collapse;
  text-align: center;
}

.propertyTable td {
  padding: 0.3rem;
  text-align: left;
}

.editProperty {
  background-color: #1890ff;
  color: #ffffff;
  border-radius: 5px;
  border: 0;
  height: 1.3rem;
  width: 1.3rem;
  margin-top: 22px;
}

.deactivateProperty {
  background-color: #01522f;
  color: #ffffff;
  border-radius: 5px;
  border: 0;
  height: 1.3rem;
  width: 1.3rem;
}

.editProperty:hover {
  cursor: pointer;
  background: #096dd9;
}

.deactivateProperty:hover {
  cursor: pointer;
  background: #018049;
}

.propertyActions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.onHover:hover {
  background: #e7e7e7;
  cursor: default;
}

/* .propertyTable tr:nth-child(even) {
  background: #e7e7e7
}  */

/* @media only screen and (max-width: 650px) {
  .properties {
    overflow-x: scroll;
  }
} */
