.mainCard{
  width: 100%;
  height: fit-content;
  min-width: 410px;
  padding-bottom: 1rem;
}

@media screen and (max-width: 650px) {
  .mainCard{
    margin: 0;
    /* margin-left: calc(1vw * -1); */
    /* margin-left: calc(-32px + 4vw); */
    min-width: 0;
  }
}
@media screen and (max-width: 420px) {
  .mainCard{
    /* margin-left: calc(-32px + 3vw);   */
  }
}
@media screen and (max-width: 342px) {
  .mainCard{
    /* margin-left: -32px; */
    /* width: 100vw; */
  }
}