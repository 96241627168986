.totalsDisplay{
  width:100%;
  max-width: 83vw;
  display: flex;
  justify-content: space-around;
  padding: 1em 0;
  order:3;
}

/* .totalsDisplay>div{
  padding: 0.5em 1em 0 1em;
} */

.totalsDisplay>div>h2{
  color: #444444;
}

@media screen and (max-width:541px) {
  .totalsDisplay>div>h2{
    font-size: large;
  }
}
@media screen and (max-width:410px) {
  .totalsDisplay>div>h2{
    font-size: medium;
  }
}
@media screen and (max-width:360px) {
  .totalsDisplay>div>h2{
    font-size: small;
  }
}
@media screen and (max-width:300px) {
  .totalsDisplay>div>h2{
    font-size: x-small;
  }
}