.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-height: 85vh; */
  width: 100%;
  height: 100%;
}

.logoContainer{
  text-align: center;
  padding-top: 5px;
  width: 100%;
  height: min-content;
}
.logoContainer>img{
  height:7em;
  width: 7em;
  background-color: white;
  border-radius: 100%;
}
.addEmployeeButton {
  opacity: 1;
  width: 100%;
  height: auto;
  transition: 0.2s ease-in;
  backface-visibility: hidden;
  border: none;
  background: none;
}

#addEmployeeButton {
  width: 1rem;
}

.addEmployeeButtonContainer:hover .addEmployeeButton {
  opacity: 0.7;
  cursor: pointer;
}

.addEmployeeButtonContainer {
  width: 2.5%;
  border: none;
  background: none;
  margin-right: 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 1500px) {
  .addEmployeeButtonContainer {
    width: 3%;
  }
}

.shadowBox{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 50vw;
  min-width: 25em;
  max-height: calc(85vh - 10.5em);
  box-shadow: 0 10px 15px 5px rgba(0,0,0,0.1);
}

.topBar{
  width:100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  border-bottom: solid 1px #dddddd;
}
.topBar>label{
  display: flex;
  align-items: center;
}
.topBar .addBtn{
  width: 1em;
  height: 1em;
  line-height: 0.8em;
  padding: 0;
  border-radius: 100%;
  border:none;
  color: white;
  background-color: #1890ff;
}
.topBar .addBtn:hover{
  opacity: 0.7rem;  
  cursor: pointer;
}

.employeeList{
  /* overflow-y: scroll; */
  width:100%;
  height:100%;
  background-color: white;
  /* overflow-y: scroll; */
}

@media screen and (max-width: 500px) {
  .logoContainer>h1{
    font-size: 5.5vw;
  }
  .logoContainer>img{
    max-width: 7em;
    width: 20vw;
    min-width: 3em;
    max-height: 7em;
    height: 20vw;
    min-height: 3em;
  }
}

@media screen and (max-width: 380px) {
  .logoContainer>h1{
    font-size: 1.5em;
  }
  .shadowBox{
    width: 98%;
    min-width: 0;
    max-width: 98vw;
  }
}