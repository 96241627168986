.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-height: 85vh; */
  width: 100%;
  height: 100%;
}

.jobDisplay {
  width: 50vw;
  display: flex;
  flex-direction: column;
  border-right: 2px #dddddd solid;
  height: 100%;
  background-color: #fafafa;
  /* padding: 0.25rem;
  overflow-x: hidden; */
}

.jobDisplayList {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.logoContainer {
  text-align: center;
  padding-top: 5px;
  width: 100%;
  height: min-content;
}
.logoContainer > img {
  height: 7em;
  width: 7em;
  background-color: white;
  border-radius: 100%;
}

.shadowBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 50vw;
  min-width: 25em;
  max-height: calc(85vh - 10.5em);
  box-shadow: 0 10px 15px 5px rgba(0, 0, 0, 0.1);
}

.topBar {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  border-bottom: solid 1px #dddddd;
}
.topBar > label {
  display: flex;
  align-items: center;
}
.topBar .addBtn {
  width: 1em;
  height: 1em;
  line-height: 0.8em;
  padding: 0;
  border-radius: 100%;
  border: none;
  color: white;
  background-color: #1890ff;
}
.topBar .addBtn:hover {
  opacity: 0.7rem;
  cursor: pointer;
}

/* .employeeList {
  width: 100%;
  height: 100%;
  background-color: white;
} */

@media screen and (max-width: 500px) {
  .logoContainer > h1 {
    font-size: 5.5vw;
  }
  .logoContainer > img {
    max-width: 7em;
    width: 20vw;
    min-width: 3em;
    max-height: 7em;
    height: 20vw;
    min-height: 3em;
  }
}

@media screen and (max-width: 380px) {
  .logoContainer > h1 {
    font-size: 1.5em;
  }
  .shadowBox {
    width: 98%;
    min-width: 0;
    max-width: 98vw;
  }
}
