.vendorTile {
  width: 100%;
}

/* #main-body {
  height: 100% !important;
} */

.preventSelection {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tile {
  display: flex;
  align-items: center;
  width: 100%;
  /* gap: 1rem; */
  padding: 0.15rem;
  font-weight: 700;
  border: 1px solid #dddddd;
  cursor: pointer;
  /* margin-bottom: 0.5rem; */

  /* color: #778ca2; */
}

.tile:hover {
  background-color: #dddddd;
}

.overdue {
  /* background-color: #ff5555; */
  border: dashed #ff0000 2px;
}

.basicTile {
  padding-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
}

.basicTile:hover {
  transition: 0.2s ease-in;
  background-color: #dddddd;
}
.overdue:hover .basicTile {
  background-color: #ff0000;
}

.tileSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jobTitle {
  color: #778ca2;
  font-weight: bold;
  font-size: 18px !important;
}

.title {
  color: #778ca2;
  font-weight: 500;
}

.comments {
  margin-bottom: 1rem;
}
.table {
  margin-top: 1rem;
  width: 100%;
  /* border-bottom: thin solid black;
  border-left: thin solid black;
  border-right: thin solid black; */

  thead th {
    text-align: center;
  }
}
.table th {
  padding-left: 0.1rem;
  text-align: left;
  font-weight: 200;
  background-color: #f0f0f0;
  /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.01) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px;     */
  /* border: thin solid black; */
}

.tableRowHover:hover {
  background-color: #dddddd;
  cursor: default;
}

.hover:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.categoryLabel {
  color: #4c81ac;
  font-size: medium;
  font-weight: 600;
}

.btnContainer {
  width: 100%;
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo {
  width: 80%;
  height: fit-content;
  max-width: 30vw;
}

#small {
  font-size: small;
}

#icon {
  width: 45%;
}

#create-button {
  width: 7rem;
  position: sticky;
  z-index: 0;
}

.box {
  padding-bottom: 1rem;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  /* align-items: center; */
  text-align: left;
  /* justify-content: center; */
  /* width: 80%; */
}

.inputLabel {
  display: inline-block;
  width: 180px;
  text-align: right;
}

.inputJR {
  display: inline-block;
  align-self: flex-start;
}

.invoiceContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  z-index: 10000;
}

.invoicePopUp {
  background-color: white;
  padding: 1rem;
  border: 3px solid #dddddd;
  border-radius: 10px;
  width: 70%;
  height: fit-content;
}

.invoiceDiv {
  text-align: left;
}

.smallInput {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.invoiceSubmit {
  margin-top: 1rem;
}

#acceptInvoice {
  margin-right: 1rem;
}

.phase {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-bottom: 1px solid #cacaca;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

#phase-2 {
  width: 100%;
}

@media screen and (max-width: 1390px) {
  #phase-2 {
    overflow-x: hidden;
    font-size: small;
  }
}
@media screen and (max-width: 1342px) {
  #phase-2 {
    font-size: x-small;
  }
}

#phase-3 {
  text-align: center;
}

.radioDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

#timeliness,
#quality,
#value,
#cleanliness,
#communication,
#communivcation {
  display: inline-block;
}

.starButton {
  /* visibility: hidden; */
  cursor: pointer;
}

@media only screen and (max-width: 550px) {
  .tile {
    font-size: 0.7rem;
  }
  #small {
    font-size: 0.4rem;
  }
  #icon {
    width: 20%;
  }
}

.wrapper {
  display: inline-block;
}
.wrapper * {
  float: right;
}
.wrapper input {
  display: none;
}
.wrapper label {
  font-size: 30px;
  cursor: pointer;
}
.filled,
.half-filled {
  color: #ebc934;
}

.wrapper input:checked ~ label {
  color: #ebc934;
}

.review-popup {
  background-color: white;
  padding: 1rem;
  border: 3px solid #dddddd;
  border-radius: 10px;
}

.review-container {
  display: flex;
  position: absolute;
  z-index: 10000000;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

#closeReviewButton {
  margin-top: 1rem;
}

#review-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-size {
  svg {
    font-size: 18px !important;
    color: #017a46 !important;
  }
}
.anticon-color {
  svg {
    color: #017a46 !important;
    font-size: 150% !important;
  }
}
