@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

body {
  color: white;
  font-family: "Nunito", sans-serif;
  background: rgb(40, 40, 40);
}

/* All users */
.all-users {
  display: flex;
  margin-bottom: 1rem;
}

.single-user {
  background: rgb(0, 213, 255);
 
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 10px;
  margin-right: 0.5rem;
  position: relative;
}

/* User Card */
.user-card {
  color: white;
  position: relative;
  border-bottom: 1px solid rgb(100, 100, 100);
  width: 380px;
}

.user-card .text-content {
  width: 170px;
  color: #263159;
}

.user-card .name {
  font-weight: 700;
}

.user-card .text,
.user-card .date {
  font-size: 14px;
  color: rgb(180, 180, 180);
}

.user-online {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgb(0, 219, 0);
  position: absolute;
  /* top: -3px;
  right: -3px;
  z-index: 2; */
}

/* Chat Box */
.chat-box {
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  background: rgb(25, 25, 25);
  border-radius: 10px;
}

.messages-box {
  height: 85vh;
}

/* Chat Header */
.chat-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  color: white;
  background: rgb(30, 30, 30);
}

/* Messages */
.messages {
  max-height: calc(100vh - 15rem);
  overflow-y: auto;
  padding: 0 2rem;
}

.message {
  color: white;
  background: #282828;
  padding: 0.75rem;
  border-radius: 5px;
  max-width: 50%;
}

.message.self {
  color: white;
  background: #00bd9b;
}

.message-footer {
  font-size: 12px;
  align-self: flex-end;
  font-weight: 400;
}

/* Chat Input  */
.chat-input {
  width: 100%;
  background: rgb(30, 30, 30);
  padding: 1rem;
}
.send-btn {
  border: none;
  background: rgba(72, 112, 223, 1);
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* Notification */
.notifications {
  position: relative;
}

.notifications-icon {
  color: white;
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
}

.notifications-box {
  max-height: 50vh;
  width: 300px;
  position: absolute;
  top: 2rem;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: #181d31;
  color: white;
  z-index: 5;
}

.notifications-header {
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.notifications-box h3 {
  font-weight: 700;
  font-size: 20px;
}

.mark-as-read {
  cursor: pointer;
  font-weight: 700;
  opacity: 0.8;
}

.notification {
  font-size: 14px;
  margin: 1rem 0;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid rgb(207, 207, 207);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.notification.not-read {
  background: #263159;
}

.notification-time {
  margin-top: 0.2rem;
  font-size: 12px;
  color: #e0e0e0;
}

.notification-count {
  display: flex;
  background: #00bd9b;
  height: 25px;
  width: 25px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -15px;
}

.this-user-notifications {
  display: flex;
  background: #00bd9b;
  height: 20px;
  width: 20px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.commmunicationLayout {
  height: calc(100% - 114px);
  background-color: #ffffff;
}
.communicationDesktop {
  margin: 30px;
}
/* #commSiderLeft {
  border-right: thin solid #e3e3e3;
} */

.commmunicationLayout .ant-layout-content{
  margin:0!important;
  padding:0!important;

}
.commmunicationLayout .ant-layout-sider,
.commmunicationLayout .ant-layout-header,
.commmunicationLayout .ant-layout-content,
.commmunicationLayout .ant-layout-footer {
  background-color: white;
}
.threadList {
  flex: 92% 1;
}

.listContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.threadLeft {
  width: 45px;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
.threadMain {
  width: 78%;
}
.threadRight {
  width: 22%;
  text-align: right;
}
.threadName {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 200px;
  
}
.threadName:hover {
  color: rgba(0, 0, 0, 1);
}

.threadListing {
  display: flex;
  background-color: white;
  width: 100%;
  border-bottom: thin solid #e3e3e3;
  height: 80px;
  padding: 8px;
}
.activeThread,.activeThread:hover{
  background-color: #e6f7ff;
}
.unreadTxt {
  font-weight: 600!important;
  font-size: 12px;
  color:rgb(0,0,0,0.85)
}
.txtMsg {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color:rgb(0,0,0,0.85)
}