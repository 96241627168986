.inputDefaults{
  padding-left: 1em;
  border-radius: 4px;
  border: solid 1px lightgray;
  height:2.6rem;
  color: gray;
  outline: none;
}
.inputDefaults:focus{
  outline: none;
  border-color: #017a46;
  box-shadow: 0 0 1px 2px #d1e9ff;
}
.inputDefaults:hover{
  outline: none;
  border-color: #017a46;
}