.chatBox{
  width: fit-content;
  max-width: 50%;
  min-width: 410px;
  overflow-y: none;
  position: fixed;
  bottom: 5px;
  right: 5px;
  padding: 0 !important;
}

.hover:hover {
  background-color: #dddddd;
}