.inputDefaults{
  padding-left: 1em;
  border-radius: 4px;
  border: solid 1px lightgray;
  height:2.6rem;
  color: #262626;
  outline: none;
}
.inputDefaults:focus{
  outline: none;
  border-color: var(--fixDenColor);
  box-shadow: 0 0 1px 2px var(--outlineShadowColor);
}
.inputDefaults:hover{
  outline: none;
  border-color: var(--fixDenColor);
}