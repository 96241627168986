.topBar{
  width: 100%;
  /* max-width: 83vw; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  margin-bottom: 0.5em;
  order:1;
}

.searchBar{
  order: 2;
  display:flex;
  height:2.5em;
  align-items: center;
  border: 2px solid #dddddd;
  border-radius: 5px;
  background-color: white;
  width: 85%;
}
.searchBar>input{
  border:none;
  padding-left: 4px;
  width: 100%;
}
.searchBar>input:focus{
  outline: none;
}
.searchIcon{
  height: 100%;
  border-left: 2px solid #dddddd;
  padding: 2px;
}
.searchBar:focus-within, .searchBar:focus-within>*{
  border-color: #1890ff;
}

.dateRange{
  order: 2;
  border: 2px solid #dddddd;
  border-radius: 5px;
  width: fit-content;
  height: 2.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: white;
  display: flex;
  justify-items: center;
}
.dateRange>input::-webkit-calendar-picker-indicator{
  margin-left: -25px;
}
.dateRange>p{
  display:inline-block;
  padding: 0;
  margin: 0;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: 0.3em;
}
.dateRange>input{
  border:none;
  width:min-content;
  background-color: white;
  outline: none;
}
.dateRange>input:focus{
  outline:none;
}
.dateRange:focus-within{
  border-color: #1890ff;
}

.searchContainer{
  order:2;
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dateRangeContainer{
  order:2;
  height: 2.5em;
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btnContainer{
  order: 3;
  width: 33%;display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

@media screen and (max-width: 710px){
  .btnContainer{
    order: 1;
    width: 25%;
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;
  }
  .dateRangeContainer{
    order: 1;
    width: 75%;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
  }
  .searchContainer{
    width: 100%;
  }
}

@media screen and (max-width: 450px){
  .btnContainer{
    order: 1;
    width: 100%;
    justify-content: center;
  }
  .dateRangeContainer{
    order: 2;
    width: 100%;
    justify-content: center;
  }
  .searchContainer{
    order:3 ;
  }
}