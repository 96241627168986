.cell {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.onHover:hover {
  background-color: #dddddd;
  cursor: pointer;
}
.onHover:hover td{
  border-color: white;
}

.shadowBox{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 50vw;
  min-width: 25em;
  max-height: calc(85vh - 10.5em);
  box-shadow: 0 10px 15px 5px rgba(0,0,0,0.1);
}

.financeTable{
  width: 100%;
  max-width: 2500px;
  min-width: 750px;
  border-radius: 10px;
  border: #dddddd solid 1px;
  overflow: hidden;
  margin-top: 1em;
}

.table{
  width: 100%;
}

.thead{
  background-color: #dddddd;
}

.tbody > tr > td{
  border: 1px solid #dddddd;
}
.tbody > tr{
  border: none;
}
.tbody > tr:last-child td{
  border-bottom: none;
}
.tbody{
  border: none;
}
.tbody td:last-child{
  border-right: none;
}

@media screen and (max-width: 1000px) {
  .financeTable{
    width: 100%;
    /* max-width: 83vw; */
    overflow-x: scroll;
  }
}

@media screen and (max-width: 875px) {
  .financeTable{
    min-width: 50px;
  }
}

@media screen and (max-width: 342px) {
  .financeTable{
    max-width: none;
  }
}
