/* .calendar {
  width: 85.3vw;
  height: fit-content;
  align-self: center;
  margin: 2rem 0 0 0;
} */

.icalButton {
  margin-bottom: 0;
}


/* .tileContainer {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
} */

.downloadButton {
  margin-right: 2.2rem;
}

@media screen and (max-width: 1535px) {
  .fc-toolbar-chunk .fc-toolbar-title  {
    font-size: 1.25rem !important;
    text-align: center;
  }
  .fc-toolbar-chunk {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 500px) {
  .fc-toolbar-chunk .fc-toolbar-title  {
    font-size: 1rem !important;
  }
  .fc-toolbar-chunk {
    font-size: 0.7rem;
  }
  .fc-col-header-cell-cushion, .fc-scrollgrid-shrink-cushion {
    font-size: 0.7rem;
  }
  .downloadButton {
    height: 2rem;
    width: fit-content;
  }
}

@media screen and (max-width: 400px) {
  .fc-toolbar-chunk .fc-toolbar-title  {
    font-size: 0.75rem !important;
  }
  .fc-toolbar-chunk {
    font-size: 0.6rem;
  }
  .fc-col-header-cell-cushion, .fc-scrollgrid-shrink-cushion {
    font-size: 0.5rem;
  }
  .downloadButton {
    height: 1.5rem;
    width: fit-content;
  }
}

.proposedEvent {
  background-color: blue;
  border: none;
  text-align: center;
  color: #ffffff;
}

.acceptedEvent {
  background-color: hotpink;
  border: none;
  text-align: center;
  color: black;
}

.onGoingEvent {
  background-color: orange;
  border: none;
  text-align: center;
  color: black;
}

.completedEvent {
  background-color: green;
  border: none;
  text-align: center;
  color: #dddddd;
}

.proposedEvent:hover, .acceptedEvent:hover, .onGoingEvent:hover, .completedEvent:hover{
  color: black;
}

.fc .fc-prev-button, .fc .fc-next-button, .fc .fc-today-button, .fc .fc-timeGridDay-button, .fc .fc-timeGridWeek-button {
  background-color: #01522f;
}

.fc .fc-prev-button:hover, .fc .fc-next-button:hover, .fc .fc-today-button:hover, .fc .fc-timeGridDay-button:hover, .fc .fc-timeGridWeek-button:hover {
  background-color: #017a46;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
  background-color: #017a46;
}

.fc .fc-today-button:disabled, .fc .fc-timeGridDay-button:disabled, .fc .fc-timeGridWeek-button:disabled {
  background-color: #01522f;
}

/* .fc .fc-media-screen {
  width: 100%;
} */