
	.appBar {
		position: relative
	}
	.layout {
		width: auto;
		margin-left: 2rem;
		margin-right: 2rem;
	}
	.paper {
		margin-top: 1rem;
		margin-bottom: 1rem;
		padding: 1rem;
	}
	.stepper {
		padding: 1rem;
	}
	.buttons {
		display: flex;
		justify-content: flex-end;
	}
	.button {
		margin-top: 3rem;
		margin-left: 1rem;
	}